import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';
import { HomeService } from 'src/app/home/home.service';


@Component({
  selector: 'app-autopay-disclosure-modal',
  templateUrl: './autopay-disclosure-modal.component.html',
  styleUrls: ['./autopay-disclosure-modal.component.scss']
})
export class AutopayDisclosureModalComponent extends DialogComponent<null, boolean> implements OnInit, OnDestroy {
  isTermsChecked: any = false;
  partyId: any;
  constructor(private modal: DialogService, private homeService: HomeService) {
    super(modal);
  }

  ngOnInit(): void {
    this.result = false;
  }

  AgreedDisclosure() {
    this.result = true;
    //alert(this.partyId);
    this.homeService.recordAcknowledgmentFromCustomer(this.partyId).subscribe(data => {
     // console.log("here" + data);
      if (data != null) {
        console.log("here1" + data);
      }

    });
    this.close();
  }


}
