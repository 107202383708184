import { Component, OnInit } from '@angular/core';
import { DialogService, DialogComponent } from 'ng2-bootstrap-modal';
import { Router } from '@angular/router';

@Component({
  selector: 'autopay-notallowed-modal-modal',
  templateUrl: './autopay-notallowed-modal.component.html',
  styleUrls: ['./autopay-notallowed-modal.component.scss']
})
export class AutopayNotAllowedModalComponent extends DialogComponent<null, boolean> {

  
  constructor(private modal: DialogService,  private router: Router) {
    super(modal);
  }

  ngOnInit() {
  }

 navigateToPage() {
    this.close();
    window.location.href = 'https://www.rentacenter.com';
  }
}