import { Component, OnInit } from '@angular/core';
import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';

@Component({
  selector: 'app-setup-autopay-processing-modal',
  templateUrl: './setup-autopay-processing-modal.component.html',
  styleUrls: ['./setup-autopay-processing-modal.component.scss']
})
export class SetupAutopayProcessingModalComponent extends DialogComponent<null, boolean> {

  constructor(
    modal: DialogService
  ) {
    super(modal);
   }

}
