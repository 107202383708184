import { Component, OnInit } from '@angular/core';
import { DialogService, DialogComponent } from 'ng2-bootstrap-modal';

@Component({
  selector: 'app-why-to-set-up-auto-pay',
  templateUrl: './why-to-set-up-auto-pay.component.html',
  styleUrls: ['./why-to-set-up-auto-pay.component.scss']
})
export class WhyToSetUpAutoPayComponent  extends DialogComponent<null, boolean> {

  constructor(
    modal: DialogService
  ) {
    super(modal);
   }

}
