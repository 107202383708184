import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {


  constructor(private http: HttpClient) { }

  private env_variable: boolean;
  correlationId: string = 'txt2AP';
  ackcorrelationId: string = 'acktxt2AP';

  getAPIBaseURL() {
    let hostName = window.location.hostname;
    if (hostName === 'localhost') {
      this.env_variable = true;
      hostName = 'dev-txt2ap.racnow.co';
      return 'https:' + '//' + hostName
    }
  }


  post(host: boolean, path: string, data: any): Observable<any> {
    this.getAPIBaseURL();
    const bodyString = data === null ? '' : JSON.stringify(data);
    const options = this.generateRequestOptions(host);
    console.log('host NAME' + window.location.hostname);
    if(host && !this.env_variable){
      return this.http.post(path, bodyString, options);
    }
    else if(host && this.env_variable){ // setting for local
      return this.http.post(this.getAPIBaseURL() + path, bodyString, options);
    }
    else{
    return this.http.post( environment.backend_url + path, bodyString, options);
    }
  }

  postAck(host: boolean, path: string, data: any): Observable<any> {
    this.getAPIBaseURL();
    //const bodyString = data === null ? '' : JSON.stringify(data);
    const options = this.generateRequestOptionsAck(host);
    console.log('host NAME' + window.location.hostname);
    if(host && !this.env_variable){
      return this.http.post(path, data, options);
    }
    else if(host && this.env_variable){ // setting for local
      return this.http.post(this.getAPIBaseURL() + path, data, options);
    }
    else{
    return this.http.post( environment.backend_acknowledge_url + path, data, options);
    }
  }


  get(path: string, headers = null): Observable<any> {
    const options = this.generateRequestOptions(null);
    return this.http.get(environment.backend_url + path, options);
  }

  private generateRequestOptions(host): any {
    return {
      headers: this.generateRequestHeader(host)
    };
  }
  private generateRequestOptionsAck(host): any {
    return {
      headers: this.generateRequestHeaderAck(host),
      responseType: 'text'
    };
  }
  private generateRequestHeader(host): HttpHeaders {

    if (host) {
      const httpOptions = new HttpHeaders({
        'Content-Type': 'application/json',
      });
      return httpOptions;
    }
    else {
      const httpOptions = new HttpHeaders({
        'x-api-key': environment.apiKey,
        'Content-Type': 'application/json',
        clientid: 'T2A',
        userid: 'T2A',
        correlationid: this.correlationId
      });
      return httpOptions;
    }
  }

  private generateRequestHeaderAck(host): HttpHeaders {
      const httpOptionsack = new HttpHeaders({
        clientid: 'ARCO',
        userid: 'ARCO',
        correlationid: this.ackcorrelationId,
        'x-api-key': environment.acknowledge_apikey,
        'Content-Type': 'application/json',
      });
      return httpOptionsack;
  }

}
