import { Component, OnInit } from '@angular/core';
import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';

@Component({
  selector: 'app-setup-autopay-success',
  templateUrl: './setup-autopay-success.component.html',
  styleUrls: ['./setup-autopay-success.component.scss']
})
export class SetupAutopaySuccessComponent extends DialogComponent<null, boolean> {

  constructor(private modal: DialogService) {
    super(modal);
  }

  navigateToPage() {
    this.close();
    window.location.href = 'https://www.rentacenter.com';
  }


}
