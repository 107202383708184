// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/* uncomment this for local
export const environment = {
  production: false,
  backend_dev: 'https://oworhg0vc5.execute-api.us-east-1.amazonaws.com',
  backend_dev_enrollAP: 'https://l6bjdkywkk.execute-api.us-east-1.amazonaws.com',
  backend_qa: 'https://lciffikcte.execute-api.us-east-1.amazonaws.com',
  backend_uat: 'https://px04dq9kc0.execute-api.us-east-1.amazonaws.com',
  backend_prod: 'https://nztpyleqp7.execute-api.us-east-1.amazonaws.com',
  payPageId: 'DKa7HK5j5f4H4hxw',
  merchantId: '1273451',
}; */



// env set up to uncomment in dev
/*export const environment = {
  production: false,
  backend_url: 'https://dev-essapi.rentacenter.com',
  apiKey: 'n5Cq45cgdr7tqJWTCSJ2gOmuDV9JHk34uNLL16md', // get keys from wiki page 
  payPageId: 'DKa7HK5j5f4H4hxw',
  merchantId: '1273451',
  backend_acknowledge_url: 'https://1ag69u04i9.execute-api.us-east-1.amazonaws.com/dev',
  acknowledge_apikey: '',// get key from wiki page
  version: '!version'
}; */

// env set up to comment in local
  export const environment = {
  production: false,
  backend_url: 'https://!backend_url',
  apiKey: '!apiKey',
  payPageId: '!payPageId',
  merchantId: '!merchantId',
  backend_acknowledge_url: 'https://!backend_acknowledge_url',
  acknowledge_apikey: '!acknowledge_apikey',
  version: '!version'
}; 
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
