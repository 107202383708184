import { Component, OnInit } from '@angular/core';
import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';
import { Router } from '@angular/router';
@Component({
  selector: 'app-password-error-modal',
  templateUrl: './password-error-modal.component.html',
  styleUrls: ['./password-error-modal.component.scss']
})
export class PasswordErrorModalComponent extends DialogComponent<null, boolean> {

  
  constructor(private modal: DialogService,  private router: Router) {
    super(modal);
  }

  ngOnInit() {
  }
  backtoScreen():void {
    this.close();
    }
}
