import { Component, OnInit } from '@angular/core';
import { DialogService, DialogComponent } from 'ng2-bootstrap-modal';
import { Router } from '@angular/router';

@Component({
  selector: 'app-generic-error-modal',
  templateUrl: './generic-error-modal.component.html',
  styleUrls: ['./generic-error-modal.component.scss']
})
export class GenericErrorModalComponent extends DialogComponent<null, boolean> {

  
  constructor(private modal: DialogService,  private router: Router) {
    super(modal);
  }

  ngOnInit() {
  }
  backtoScreen():void {
    this.close();
    }
}