import { Component, OnInit } from '@angular/core';
import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';
import { Router } from '@angular/router';

@Component({
  selector: 'app-connection-issue-modal',
  templateUrl: './connection-issue-modal.component.html',
  styleUrls: ['./connection-issue-modal.component.scss']
})
export class ConnectionIssueModalComponent extends DialogComponent<null, boolean> {

  constructor(private modal: DialogService,  private router: Router) {
    super(modal);
  }

  backtoboard() {
    this.close();
    this.router.navigateByUrl('/agreements');
  }
}
