import { Component, OnInit } from '@angular/core';
import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';
import { Router } from '@angular/router';
import { RegisterationModalComponent } from '../registeration-modal/registeration-modal.component';

@Component({
  selector: 'app-card-issue-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss']
})
export class ErrorModalComponent  extends DialogComponent<null, boolean> {

  
  constructor(private modal: DialogService,  private router: Router) {
    super(modal);
  }
  errorStr: string = 'Oops! That email address is already in use.';

  getError(): String {
    return this.errorStr;
   }

   setError(str): void {
    this.errorStr= str;
   }

  backtoboard():void {
    this.close();
    }
 
}
