import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [{
  path: 'agreements',
  loadChildren: 'src/app/home/home.module#HomeModule',
}];

@NgModule({
// SIMS-6795 Refresh WebApp UI navigates to an error page
//  imports: [RouterModule.forRoot(routes)],
  imports: [RouterModule.forRoot(routes, {useHash: true})], 
  exports: [RouterModule]
})
export class AppRoutingModule { }
