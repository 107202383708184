import { Component, OnInit } from '@angular/core';
import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';
import { Router } from '@angular/router';

@Component({
  selector: 'app-card-issue-modal',
  templateUrl: './card-issue-modal.component.html',
  styleUrls: ['./card-issue-modal.component.scss']
})
export class CardIssueModalComponent  extends DialogComponent<null, boolean> {

  constructor(private modal: DialogService,  private router: Router) {
    super(modal);
  }

  backtoboard() {
    this.close();
    this.router.navigateByUrl('/agreements');
  }
}
